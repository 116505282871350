import React from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { SOURCES } from 'activity/constants'
import styled from 'styled-components'
import theme from 'styles/theme'
import useLogForm from 'activity/hooks/useLogForm'
import { UNITS } from 'constants/log'
import Checkbox from '@material-ui/core/Checkbox'

export default function LogForm() {
  const { FITBIT, SIDEKICK_CORE } = SOURCES

  const {
    updateKpi,
    checked,
    formik,
    data,
    defaultTextFieldOptions,
    distanceFieldOptions,
    updateConversionFactor,
    conversionFactor,
    formSubmtited
  } = useLogForm()

  return (
    <LogForm.Stylesheet>
      <form>
        <div>
          <FormControlLabel
            control={<Checkbox />}
            label="KPI"
            labelPlacement="end"
            onChange={updateKpi}
            checked={checked}
            value={formik.values.kpi}
            style={{ marginLeft: '1%', marginTop: '7px' }}
          />
        </div>
        <div className="container">
          {data?.source === SIDEKICK_CORE && (
            <>
              <TextField
                {...defaultTextFieldOptions}
                label="Sets"
                name="setNumber"
                type="number"
                value={formik.values.setNumber}
                hidden={formik.values.setHidden}
              />
              <TextField
                {...defaultTextFieldOptions}
                label="Reps"
                name="reps"
                type="number"
                value={formik.values.reps}
              />
              <TextField
                {...defaultTextFieldOptions}
                label="Weight (lbs)"
                name="weight"
                type="number"
                value={formik.values.weight}
              />
              <TextField
                {...defaultTextFieldOptions}
                disabled
                label="1RM%"
                name="onerepmax"
                type="number"
                value={formik.values.oneRepMax}
                hidden={formik.values.oneRepMaxHidden}
              />
              {!formik.values.distanceHidden &&
                  <div>
                    <TextField
                      {...distanceFieldOptions}
                      style={{ width: '54.5%' }}
                      label="Distance"
                      name="distance"
                      type="number"
                      value={formik.values.distance}
                      hidden={formik.values.distanceHidden}
                    />
                    <FormControl
                      variant="outlined"
                      style={{
                        marginTop: '15px',
                        width: '44.5%'
                      }}
                      hidden={formik.values.distanceHidden}
                      disabled={formSubmtited}
                    >
                      <Select
                        value={conversionFactor}
                        onChange={updateConversionFactor}
                        hidden={formik.values.distanceHidden}
                      >
                        {Object.keys(UNITS).map((k) => (
                          <MenuItem key={k} value={UNITS[k]}>
                            {k}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
              }
              <TextField
                {...defaultTextFieldOptions}
                label="RIR"
                name="rir"
                type="number"
                value={formik.values.rir}
                hidden={formik.values.rirHidden}
              />
              <TextField
                {...defaultTextFieldOptions}
                label="Tempo"
                name="tempo"
                value={formik.values.tempo}
                hidden={formik.values.tempoHidden}
              />
            </>
          )}
          {data?.source === FITBIT && (
            <TextField
              {...defaultTextFieldOptions}
              label="Calories Burned"
              name="calories"
              type="number"
              value={formik.values.calories}
            />
          )}

          <TextField
            {...defaultTextFieldOptions}
            label="Work Time (s)"
            name="workTime"
            type="number"
            value={formik.values.workTime}
          />
          <TextField
            {...defaultTextFieldOptions}
            label="Rest Time (s)"
            name="restTime"
            type="number"
            value={formik.values.restTime}
          />
        </div>
        <TextField
          {...defaultTextFieldOptions}
          label="Notes"
          multiline
          name="notes"
          rows={3}
          value={formik.values.notes}
        />

        {!formSubmtited && (
          <button
            className="submitBtn"
            type="submit"
            onClick={formik.handleSubmit}
          >
            Mark as done
          </button>
        )}
      </form>
    </LogForm.Stylesheet>
  )
}

LogForm.Stylesheet = styled.div`
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    padding: 0;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #e52328;
  }

  .submitBtn {
    margin-top: 4rem;
    border: 0;
    background-color: ${theme.palette.secondary.g800};
    color: #fff;
    padding: 1rem;
    width: 100%;
    border-radius: 0.625rem;
    font-weight: 700;
    font-size: 0.875rem;
    text-transform: uppercase;
  }
`
