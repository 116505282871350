/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react'
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import useAppTitle from 'common/hooks/useAppTitle'
import {
  Box,
  Tab,
  Tabs,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import LeftSideBar from 'common/components/LeftSideBar'
import Button from '@material-ui/core/Button'
import TabPanel from './TabPanel'
import RightSideBar from './RightSideBar'
import WeekTabs from './WeekTabs'
import useApi from 'common/hooks/useApi'
import CreateProgram from 'common/components/CreateProgram'
import TabWeekPanel from 'common/components/TabWeekPanel'
import AddWeek from 'common/components/AddWeek'
import DeleteWeek from 'common/components/DeleteWeek'
import { saveAs } from "file-saver";
import { pdf, Document, Page, View, Text, StyleSheet, Font } from "@react-pdf/renderer";
import { useDispatch, useSelector } from 'react-redux'
import {
    GetListExerciseAction,
    GetListProgramDaysAction,
    GetListProgramWeeksAction,
    SelectedWeeksList,
    GetListProgramAction,
    SelectedProgramId,
    GetListGroupsAction,
    GetListBlocksAction,
    } from 'Redux/Action/programBuilderAct'
import { UserContext } from 'account'

Font.register({
  family: 'Courier',
  fonts: [
    {
      src: "Courier-Bold",
    },
    {
      src: "Courier-Oblique"
    },
    {
      src: "Courier-BoldOblique"
    },
  ]
});
const styles = StyleSheet.create({
  table: {
    width: '100%',
  },
  tableCommon: {
    width: "100%",
    border: "1px solid #eeeeee",
    borderRadius: "4px",
    margin: "5px",
    backgroundColor: "white"
  },
  tableExercises: {
    width: "100%",
    border: "1px solid #eeeeee",
    borderRadius: "4px",
    margin: "5px",
    backgroundColor: "white",
    display: 'flex',
    flexDirection: 'row',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
  },
  weekName: {
    fontSize: "18px",
    textAlign: "center",
    textTransform: "capitalize",
    marginTop: "5px",
//    fontFamily: "Courier-Bold"
  },
  mainPage: {
    padding: "15px",
    fontSize: "12px",
    backgroundColor: "#f9f9f9",
    color: "#1d1d1d"
  },
  programName: {
    fontSize: "16px",
    color: "#1d1d1d",
    borderBottom: '1px solid #eeeeee',
    padding: "10px",
    marginTop: "5px",
  },
  groupTitle: {
    fontSize: "14px",
    color: "white",
    backgroundColor: "#0B545E",
    marginTop: "5px",
  },
  groupName: {
    fontSize: "14px",
    color: "white",
    backgroundColor: "#0B545E",
    marginTop: "5px"
  },
  blockTitle: {
    fontSize: "14px",
    backgroundColor: "#dedede"
  },
  blockName: {
    fontSize: "14px",
    backgroundColor: "#dedede",
    marginTop: "5px"
  },
  exerciseTitle: {
    fontSize: "12px",
    marginTop: "5px",
    width: "60px",
    paddingRight: "2%"
  },
  exercise: {
    fontSize: "12px",
    marginTop: "5px",
    paddingLeft: "2%",
    width: "60px",
  },
  notes: {
    fontSize: "12px",
    marginTop: "5px",
    paddingRight: "2%",
    width: '120px'
  },
  exerciseName: {
    fontSize: "12px",
    marginTop: "5px",
    width: "120px",
    paddingRight: "3%"
  },
  exerciseNameTitle: {
    fontSize: "12px",
    marginTop: "5px",
    width: "120px",
    paddingRight: "5%"
  },
  spacing: {
    margin: "5px"
  },

  border: {
    border: "1px solid #eeeeee",
    margin: "4px",
    borderRadius: "4px",
    paddingLeft: "5px"
  }
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function ProgramBuilder() {
  const user = useContext(UserContext)
  let { GroupList, programWeekInfo, programID, programDayInfo } = useSelector(state => state.rootReducer.programBuilder)

  const [value, setValue] = React.useState(programWeekInfo?.data[0]?.id)
  const [showPSidebar, setShowPSidebar] = useState(false)
  const [RightSidebar, setRightSidebar] = useState(false)
  const [open, setOpen] = React.useState(false);
  const [ExerciseData, setExerciseData] = useState(null)
  const [value1, setValue1Data] = useState(0)
  const [BlockData, setBlockData] = useState(null)
  const [weekIds, setWeekIds] = useState([])
  const [GroupData, setGroupData] = useState(null)
  const [DayTitleData, setDayTitleData] = useState(null)
  const [WeekData, setWeekData] = useState([])
  const [programDays, setProgramDay] = useState([])
  const [listWeek, setListWeek] = useState([])
  const [, execute] = useApi()
  const dispatch = useDispatch()
  const [programDetail, setProgramDetail] = useState('')
  const [openprogramList, setopenprogramList] = React.useState(false);
  const [details, setDetails] = useState()

  const { programs, groupInfo, blockInfo, exerciseInfo } = useSelector(state => state.rootReducer.programBuilder)

  const WeekName = (week) => {
    setValue(week)
//    dispatch(GetListProgramDaysAction())
  }

const handleProgramChange = (event) => {
    dispatch(GetListProgramWeeksAction())
    dispatch(GetListProgramDaysAction())
    if (exerciseInfo?.length == 0) {
        dispatch(GetListExerciseAction())
    }
    if (!blockInfo.loading && blockInfo.data.length == 0) {
        dispatch(GetListBlocksAction())
    }
    if (!groupInfo.loading && groupInfo.data.length == 0) {
        dispatch(GetListGroupsAction())
    }
    setProgramDetail(event.target.value)
    const programData = programs?.data?.find((program) => {
        return program.id === event.target.value
    })
    const weekData2 = programWeekInfo.data.filter((week) => {
        return week.program === programData.id
    })
    const weeks = weekData2.map((week) => week.id)
    const dayData = programDayInfo.data.filter(day => weeks.includes(day.programWeek))
    let cleanedData = []
    for (let i = 0; i < weeks.length; i++) {
        cleanedData.push({weekIndex: i, days: programDayInfo.data.filter(day => day.programWeek === weeks[i])})
    }
    programs?.data?.forEach((name) => name.id === event.target.value && setDetails(cleanedData))
    dispatch(SelectedProgramId(event.target.value))
}

  const handleProgramListClose = () => {
        setopenprogramList(false);
    }

    const handleProgramListOpen = () => {
        if (!user.id) {
            window.location.href = '/'
        }
        setopenprogramList(true);
    }

  const toggleDrawer = (open) => (event) => {
    if (!user.id) {
            window.location.href = '/'
        }
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setRightSidebar(open)
  }

  const handleChange = (event, newValue) => {
    setValue1Data(newValue)
  }

  const handleOpen = () => {
    createProgram();
    setOpen(true);
  }

  const addCurrentWeek ={
    currentWeek: value,
  }

  const deleteWeekById = () => {
    alert(`${value} deleted successfully!`)
  }

  function handlePSidebarCollapse() {
    dispatch(GetListProgramDaysAction())
    return setShowPSidebar(false)
  }

  function openPSidebarCollapse() {
    return setShowPSidebar(true)
  }

  useEffect(() => {
    if (programID) {
        setProgramDetail(programID)
        const dataFilter = programWeekInfo?.data?.filter((data)=> parseInt(programID) === data.program )
        setListWeek(dataFilter)
        if (dataFilter.length > 0) {
            setValue(dataFilter[0].id)
            dispatch(SelectedWeeksList(dataFilter))
        }
    }
  }, [programID, programWeekInfo])

  const createProgram = () => {

  }
  const generatePDF = async () => {
    if (programID) {
    const blob = await pdf(
      <Document>
        <Page style={styles.mainPage} wrap size="A4" orientation="landscape">
          {
            details?.map((week, index) => (
              <View key={index} wrap={true}>
                {week?.days.map((day, index1) => (
                    <View key={index1}>
                        <Text style={styles.programName}>Week {index+1}</Text>
                        <Text style={styles.programName}>Day {index1+1}</Text>
                        <View >
                          <View >
                            {
                              day?.groups.map(groups => (
                                <>
                                  <View style={styles.tableCommon}>
                                    <Text style={styles.groupName}> Group:    {groups?.group.name}</Text>
                                    {
                                      groups?.group.blocks.map(block => (
                                        <>
                                          <View style={styles.border}>
                                            <View style={styles.spacing}>
                                              <Text style={styles.blockName}> Block:    {block?.name}</Text>
                                              <Text style={styles.blockName}> Exercises</Text>
                                            <View style={styles.row}>
                                              <Text style={styles.exerciseNameTitle}>Name</Text>
                                              <Text style={styles.exerciseTitle}>Set</Text>
                                              <Text style={styles.exerciseTitle}>Reps</Text>
                                              <Text style={styles.exerciseTitle}>Weight</Text>
                                              <Text style={styles.exerciseTitle}>Work Time</Text>
                                              <Text style={styles.exerciseTitle}>Rest Time</Text>
                                              <Text style={styles.exerciseTitle}>Distance</Text>
                                              <Text style={styles.exerciseTitle}>1RM %</Text>
                                              <Text style={styles.exerciseTitle}>KPI</Text>
                                              <Text style={styles.exerciseTitle}>RIR</Text>
                                              <Text style={styles.exerciseTitle}>Notes</Text>
                                            </View>
                                            </View>
                                                {block?.exercises.length > 0 &&
                                                  block?.exercises.map((exercise, index) => (
                                                    <View key={block.id + index} style={styles.tableExercises}>
                                                      <View style={styles.row}>
                                                      <Text style={styles.exerciseName}> {exercise.exercise.name} </Text>
                                                      <Text style={styles.exercise}> {exercise.set_number} </Text>
                                                      <Text style={styles.exercise}> {exercise.reps} </Text>
                                                      <Text style={styles.exercise}> {Number(exercise.weight_lbs)} </Text>
                                                      <Text style={styles.exercise}> {exercise.work_time_seconds} </Text>
                                                      <Text style={styles.exercise}> {exercise.rest_time_seconds} </Text>
                                                      <Text style={styles.exercise}> {Number(exercise.distance)} </Text>
                                                      <Text style={styles.exercise}> {Number(exercise.one_rep_max_percent)} </Text>
                                                      <Text style={styles.exercise}> {exercise.kpi ? 'Yes' : ''}</Text>
                                                      <Text style={styles.exercise}> {exercise.reps_in_reserve} </Text>
                                                      <Text style={styles.notes}> {exercise.notes}</Text>
                                                      </View>
                                                    </View>
                                                  ))
                                                }
                                          </View>
                                        </>
                                      ))
                                    }
                                  </View>
                                </>
                              ))
                            }
                          </View>
                        </View>
                    </View>
                ))}
                {
                  index === WeekData?.length - 1 ? <View></View> : <View break></View>
                }
              </View>
            ))
          }
        </Page>
      </Document >
    ).toBlob();
    saveAs(blob, details?.name);
    } else {
        alert('Select a Program First')
    }
  }

  useEffect(() => {
    if (programWeekInfo.data.length > 0) setValue(programWeekInfo.data[0].id)
  }, [])

  return (
    <>
      <LeftSideBar collapse={!showPSidebar} onCollapse={handlePSidebarCollapse} />
      <Box className='top_page_bar' style={{margin: '0px 20px'}}>
        <Box className='main-header' sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Box className='builder-title' sx={{ display: "flex" }}>
            <Button onClick={() => {
              if (!user.id) {
                window.location.href = '/'
              }
              dispatch(GetListExerciseAction())
              openPSidebarCollapse()
            }}
            variant='outlined'>
              Library &nbsp; <MenuBookOutlinedIcon/>
            </Button>
          </Box>
          <FormControl variant="standard" style={{ minWidth: '30vw' }}>
            <InputLabel id="demo-simple-select-standard-label">Select Program</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select-label"
                openprogramList={openprogramList}
                onClose={handleProgramListClose}
                onOpen={handleProgramListOpen}
                value={programDetail}
                label="Programs"
                onChange={handleProgramChange}
            >
                {programs?.data.map((programs) => <MenuItem value={programs.id}>{programs.name}</MenuItem>)}
            </Select>
          </FormControl>
          <Box className='button-section' id="content" sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "15px", }}>

            <Box className='save_button-create'
              sx={{
                background: "#43818b",
                boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),0px 2px 4px rgba(0, 0, 0, 0.14)",
                borderRadius: "100px",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                padding: "5px 10px"
              }}>
              <Button
                className="save_button-create"
                style={{ color: '#fff' }}
                onClick={handleOpen}>+ CREATE</Button>
            </Box>
            <Box className='save_button-save' sx={{
                  background: "#43818b",
                  boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),0px 2px 4px rgba(0, 0, 0, 0.14)",
                  borderRadius: "100px",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  padding: "5px 10px"
                }}>
              <Button style={{ color: '#fff' }} onClick={generatePDF}>
                <ion-icon
                  name="download"
                  role="download"
                  className="save_button-save"
                  aria-label="download"
                /> &nbsp; Download pdf
              </Button>
            </Box>
            <Button variant='outlined' onClick={toggleDrawer(true)}>
                Details &nbsp; <AssessmentOutlinedIcon/>
            </Button>
          </Box>
        </Box>
        <CreateProgram open={open} setOpen={setOpen} weekIds={weekIds} />
      </Box>
      <Drawer aria-label='thisIsDrawer' variant="persistent" anchor="right" open={RightSidebar}>
        <RightSideBar
          toggleDrawer={toggleDrawer}
          setRightSidebar={setRightSidebar}
        />
      </Drawer>
      <Box style={{ width: '100%', marginTop: "43px" }}>
        <Box style={{ borderBottom: 1, borderColor: 'divider', px: 10, }}>
          <Tabs
            value={value1}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
          >
            {
             listWeek.length>0 && listWeek?.map((item, index) => (
                <Tab key={item?.name} label={`week ${index + 1}`} style={{ fontSize: "17px", color: "black", fontWeight: "600" }} onClick={() => WeekName(item?.id)} {...a11yProps(item?.name)} />
              ))
            }
            <AddWeek addCurrentWeek={addCurrentWeek} />
            <DeleteWeek value={value} deleteWeekById={deleteWeekById} />
          </Tabs>
          <TabWeekPanel value={value} index={value} WeekData={WeekData} ExerciseData={ExerciseData} BlockData={BlockData} GroupData={GroupData} DayTitleData={DayTitleData} />
        </Box>
      </Box>
    </>
  )
}

export default ProgramBuilder
