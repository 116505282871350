import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";
import { UpdateProgramExerciseAction } from "../../Redux/Action/programBuilderAct"

function SelectRenderer ({ api, data }) {

    const { exerciseInfo } = useSelector(state => state.rootReducer.programBuilder)
    const dispatch = useDispatch()
    const onChange = (event) => {
        if (event) {
            const payload = {
                    exercise_pk: event?.id,
                    exercise: event?.id,
                    set_number: Number(data.set_number),
                    weight_lbs: Number(data.weight_lbs),
                    distance_meters: Number(data.distance_meters),
                    reps: Number(data.reps),
                    reps_in_reserve: Number(data.reps_in_reserve),
                    one_rep_max_percent: Number(data.one_rep_max_percent),
                    tempo: data.tempo,
                    work_time_seconds: Number(data.work_time_seconds),
                    rest_time_seconds: Number(data.rest_time_seconds),
                    notes: data.notes,
                    kpi: data.kpi,
                    position: data.rowIndex,
                    block_id: data.block_id,
                    source: data?.source
            }
            if (api) {
                data.value = {name: event?.label, id: event?.id, label: event?.label}
                api.applyTransaction({ update: [{
                    id: data.id,
                    exercise: data.value,
                    exercise_pk: data.value.id,
                    set_number: Number(payload.set_number),
                    weight_lbs: Number(payload.weight_lbs),
                    distance_meters: Number(payload.distance_meters),
                    reps: Number(payload.reps),
                    reps_in_reserve: Number(payload.reps_in_reserve),
                    one_rep_max_percent: Number(payload.one_rep_max_percent),
                    tempo: payload.tempo,
                    work_time_seconds: Number(payload.work_time_seconds),
                    rest_time_seconds: Number(payload.rest_time_seconds),
                    notes: payload.notes,
                    kpi: payload.kpi,
                    position: payload.rowIndex,
                    block_id: payload.block_id,
                    value: data.value,
                }] })
            }
            dispatch(UpdateProgramExerciseAction({ updateData: payload, id: data.id }))
        }
    }
    return(
        <div>
            <Autocomplete
              defaultValue={{label: data.value?.name || data.value, id: data.value?.id || data.data?.exercise_pk}}
              options={exerciseInfo?.data.map((option) => ({label: option.name, id: option.id}))}
              getOptionLabel={(option) => option?.label || ''}
              sx={{ width: 400 }}
              renderInput={(params) => <TextField {...params} variant='standard' />}
              onChange={(e, value) => onChange(value)}
            />
        </div>
    )
}

export default SelectRenderer
