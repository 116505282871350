import React, { useContext, useState } from 'react'
import {
  exerciseIcon,
  groupInfoDarkIcon,
  groupInfoLightIcon,
  runningIcon,
  infoIcon,
  markAsDoneIcon,
  repsIcon,
  setsIcon,
  weightIcon
} from 'constants/assets'
import styled from 'styled-components'
import { Check, ChevronRight } from '@mui/icons-material'
import theme from 'styles/theme'
import { ActivityLogContext, openLogDrawerAction } from 'activity/ducks'
import getExercisesMapByGroupsAndBlocks from 'services/getExercisesMapByGroupsAndBlocks'
import useApi from 'common/hooks/useApi'
import Tooltip from '@mui/material/Tooltip'
import useComponentLifeCycle from 'common/hooks/useComponentLifeCycle'

import LogDrawer from './LogDrawer'

function Exercise({ data, checked, showMarkAsDone, index }) {
  const exerciseListByBlocks = Array.from(data.blockMap.entries())
  const { state, dispatch } = useContext(ActivityLogContext)

  const { isComponentMounted } = useComponentLifeCycle()

  const [isDone, setIsDone] = useState(false)
  const [open, setOpen] = useState(false)
  const [blockOpen, setBlockOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleBlockTooltipClose = () => {
    setBlockOpen(false);
  };

  const handleBlockTooltipOpen = () => {
    setBlockOpen(true);
  };


  const [exerciseListState, setExerciseListState] =
    useState(exerciseListByBlocks)

  const [, addGroup] = useApi(
    {
      method: 'POST',
      url: 'metrics/program_plan/add_group/'
    },
    { manual: true }
  )

  const [, addBlock] = useApi(
    {
      method: 'POST',
      url: 'metrics/program_plan/add_block/'
    },
    { manual: true }
  )

  const logGroup = async (exercises) => {
    const { groupId, programInfo } = exercises[0]
    try {
      const body = { id: groupId, programInfo }
      await addGroup({ data: body })

      if (isComponentMounted) {
        setIsDone(true)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const logBlock = async (exercises) => {
    const { blockId, programInfo } = exercises[0]

    try {
      const body = { id: blockId, programInfo }
      await addBlock({ data: body })

      if (isComponentMounted) {
        const exercisesList = [...JSON.parse(JSON.stringify(exerciseListState))]
        exercisesList.forEach(([, exercisesEl]) => {
          exercisesEl.forEach((exercise) => {
            if (exercise.blockId === blockId) {
              exercise.isDone = true
            }
          })
        })

        setExerciseListState(exercisesList)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const styles = [
    {
      backgroundColor: '#E6EFEC',
      color: '#09555D'
    },
    {
      backgroundColor: '#EBF5F7',
      color: '#186975'
    }
  ]

  const groupInfoIcon = index % 2 === 0 ? groupInfoLightIcon : groupInfoDarkIcon

  return (
    <div className="exerciseList" style={styles[index % 2]}>
      <div className="groupName">
        <div className="name">
          <Tooltip
            title={data?.[0]?.groupDescription}
            onClose={handleTooltipClose}
            open={open}
            disableHoverListener
            >
              <button className="groupInfo" type="button" onClick={handleTooltipOpen}>
                <h2>{data?.[0]?.group}</h2>
                <img src={groupInfoIcon} alt="Info Icon" />
              </button>
          </Tooltip>
        </div>

        {showMarkAsDone &&
          (checked || isDone ? (
            <div className="groupDone">
              <Check
                sx={{
                  color: '#fff'
                }}
              />
              <span>Done</span>
            </div>
          ) : (
            <button
              className="groupMarkAsDone"
              type="button"
              onClick={() => logGroup(data)}
            >
              <img src={markAsDoneIcon} alt="Mark as Done Icon" />
              <span>Mark as Done</span>
            </button>
          ))}
      </div>
      {exerciseListState.map(([blockName, exercises], i) => (
        <div key={`block-${blockName}-${String(i)}`}>
          <div className="header">
            <div className="groupAndBlock">
              <div className="block">
                <Tooltip
                  title={data?.[i]?.blockDescription}
                  onClose={handleBlockTooltipClose}
                  open={blockOpen}
                  disableHoverListener
                    >
                    <button className="block" type="button" onClick={handleBlockTooltipOpen}>
                      <h3>{blockName}</h3>
                      <img src={infoIcon} alt="Info Icon" />
                    </button>
                </Tooltip>
              </div>
              {showMarkAsDone &&
                (checked || exercises[0].isDone || isDone ? (
                  <div className="done">
                    <Check
                      sx={{
                        color: '#fff'
                      }}
                    />
                    <span>Done</span>
                  </div>
                ) : (
                  <button
                    className="markAsDone"
                    type="button"
                    onClick={() => logBlock(exercises)}
                  >
                    <img src={markAsDoneIcon} alt="Mark as Done Icon" />
                    <span>Mark as Done</span>
                  </button>
                ))}
            </div>
          </div>
          {exercises.map((exercise, idx) => (
            <div className="exerciseItem" key={`exercise-${String(idx)}`}>
              <button
                className="exerciseButton"
                type="button"
                onClick={() => dispatch(openLogDrawerAction(exercise))}
              >
                <div className="image">
                  <img src={exerciseIcon} alt="Exercise Icon" />
                </div>
                <div className="description">
                  <h3>{exercise.name}</h3>
                  <div className="setsAndReps">
                    <div className="sets">
                      <img src={setsIcon} alt="Sets Icon" />
                      <span>{exercise.set} Sets</span>
                    </div>
                    {!!exercise.weight &&
                      <div className="weight">
                        <img src={weightIcon} alt="Weight Icon" />
                        <span>{exercise.weight} lbs</span>
                      </div>
                    }
                    {!!Number(exercise.distance) &&
                      <div className="distance">
                        <img src={runningIcon} alt="Distance Icon" />
                        <span>{Number(exercise.distance)} m</span>
                      </div>
                    }
                    <div className="reps">
                      <img src={repsIcon} alt="Reps Icon" />
                      <span>{exercise.reps} Reps</span>
                    </div>
                  </div>
                </div>
                <div>
                  {checked ||
                    exercise.isDone ||
                    isDone ||
                    state.programDialog.exerciseStatuses?.[exercise.id] ? (
                    <Check sx={{ color: theme.palette.secondary.g800 }} />
                  ) : (
                    <ChevronRight
                      sx={{ color: theme.palette.secondary.g800 }}
                    />
                  )}
                </div>
              </button>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default function Exercises({
  checked,
  showMarkAsDone = false,
  workoutMode = false,
  currentExerciseNumber = 0
}) {
  const {
    state: {
      programDialog: { data }
    }
  } = useContext(ActivityLogContext)

  const exercisesMap = getExercisesMapByGroupsAndBlocks(
    workoutMode
      ? data.exercises?.slice(currentExerciseNumber + 1)
      : data.exercises
  )

  if (data) {
    return (
      <Exercises.Stylesheet>
        <div className="exercises">
          {Array.from(exercisesMap.entries()).map(([name, exercise], idx) => (
            <Exercise
              key={`exercise-${name}-${String(idx)}`}
              name={name}
              data={exercise}
              checked={checked}
              showMarkAsDone={showMarkAsDone}
              index={idx}
            />
          ))}
        </div>

        <LogDrawer />
      </Exercises.Stylesheet>
    )
  }
}

Exercises.Stylesheet = styled.div`
  .exercises {
    padding: 0 1rem 1rem 1rem !important;
    margin-top: 2rem;

    height: 40rem;
    overflow-y: auto;

    .exerciseList {
      border: 1px solid #e5f0fa;
      margin: 1rem 0 1rem 0;
      padding: 1rem;
      border-radius: 0.625rem;

      .groupName {
        display: flex;
        align-items: start;
        justify-content: space-between;
        color: inherit;

        .name {
          display: flex;
          align-items: start;
          img {
            margin-top: 0.2rem;
            margin-left: 0.5rem;
          }

          h2 {
            max-width: 13rem;
            font-size: 1rem;
            margin: 0;
            color: inherit;
          }
          .groupInfo {
            border: 0;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            h2 {
              color: #09555D;
              margin-right: 0.5rem;
              font-size: 1.25rem;
              max-width: 13rem;
              margin: 0;
            }
          }
        }

        .groupMarkAsDone {
          font-weight: 510;
          font-family: 'Open Sans', sans-serif;
          span {
            margin-left: 0.5rem;
          }

          border-radius: 0.3125rem;
          background-color: #f3f3f3;
          border: 1px solid #c5d0cd80;
        }

        .groupDone {
          span {
            margin-left: 0.5rem;
            font-family: 'Open Sans', sans-serif;
          }
          background-color: #18bba9;
          color: #fff;
          padding: 0 0.75rem 0 0.75rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 6rem;
          border-radius: 0.3125rem;
        }
      }

      .header {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        font-family: 'Open Sans', sans-serif;
        flex-wrap: wrap;

        .done {
          span {
            margin-left: 0.5rem;
          }
          background-color: #18bba9;
          color: #fff;
          padding: 0 0.75rem 0 0.75rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 6rem;
          border-radius: 0.3125rem;
        }

        .groupAndBlock {
          margin-bottom: 0.5rem;
          display: flex;
          width: 100%;
          img {
            width: 1.2rem;
          }

          .group {
            display: flex;
            align-items: center;
            margin-right: 0.5rem;
            font-weight: 510;
            font-size: 1rem;
            font-family: 'Open Sans', sans-serif;
            letter-spacing: 0.1rem;
            background-color: #198f96;
            border-radius: 0.3125rem;
            padding: 0.25rem 0.5rem 0.25rem 0.5rem;
            h3 {
              color: #fff;
              margin-right: 0.5rem;
              font-size: 0.75rem;
            }
          }

          .block {
            border: 0;
            margin-right: 0.5rem;
            display: flex;
            align-items: center;
            font-weight: 510;
            font-size: 1rem;
            font-family: 'Open Sans', sans-serif;
            letter-spacing: 0.1rem;
            background-color: #4064aa;
            border-radius: 0.3125rem;
            padding: 0.25rem 0.25rem 0.25rem 0.25rem;

            h3 {
              color: #fff;
              margin-right: 0.25rem;
              font-size: 1 rem;
            }
          }
        }

        .dot {
          width: 0.25rem;
          height: 0.25rem;
          background-color: #c4c4c4;
          margin: 0;
          margin-right: 1rem;
          border-radius: 0.5rem;
        }

        div {
          display: flex;
        }

        h3 {
          margin: 0;
          padding-right: 1rem;
        }

        .markAsDone {
          display: flex;
          align-items: center;
          border-radius: 0.3125rem;
          border: 0;

          background-color: #f3f3f3;
          border: 1px solid #c5d0cd80;

          font-weight: 510;

          span {
            margin-left: 0.5rem;
          }
        }
      }

      .exerciseItem {
        margin-top: 0.5rem;
        border: 0.0625rem solid #e5f0fa;
        border-radius: 0.625rem;
        padding: 1rem 0.5rem 1rem 0.5rem;
        background-color: #fff;

        .exerciseButton {
          border: 0;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .image {
            background-color: #f6fcfd;
            border-radius: 1.25rem;
            width: 3.75rem;
            height: 3.75rem;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 1.3rem;
            padding-right: 1.3rem;

            @media screen and (max-width: 450px) {
              padding-left: 0.3rem;
              padding-right: 0.3rem;
            }
          }

          .description {
            margin-left: 1rem;
            margin-right: 1rem;
            width: 100%;

            h3 {
              padding: 0;
              text-align: left;
              font-weight: 700;
              font-size: 1rem;
              margin: 0;
            }

            .setsAndReps {
              display: flex;
              font-size: 0.625rem;
              font-weight: 600;
              color: #3a4750;

              span {
                margin-left: 0.2rem;
              }

              .reps, .weight, .distance {
                margin-left: 0.5rem;
              }

              @media screen and (max-width: 420px) {
                span {
                  margin-left: 0.1rem;
                }

                .reps, .weight, .distance {
                  margin-left: 0.3rem;
                }
              }
            }
          }
        }
      }
    }
  }
`
