import { useCallback, useEffect, useState } from 'react'
import { useAuth } from 'auth'
import axios from 'axios'
import useAxios from 'axios-hooks'
import { camelCase, map, mapKeys, snakeCase } from 'lodash/fp'
import { API_URL } from 'common/env'

function transformRequest(data) {
  if (!data || data instanceof FormData) {
    return data
  }

  return mapKeys((key) => snakeCase(key))(data)
}

function transformResponse(data) {
  const camelCaseKeys = mapKeys((key) => camelCase(key))

  if (Array.isArray(data)) {
    return map((item) => camelCaseKeys(item))(data)
  }

  return camelCaseKeys(data)
}

function useApi(config, options) {
  const { getAccessTokenSilently } = useAuth()
  const [data, setData] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(!options?.manual)
  const [response, setResponse] = useState()

  const defaultConfig = {
    baseURL: `${API_URL}/api/`,
    headers: { 'Content-Type': 'application/json' },
    transformRequest: [transformRequest, ...axios.defaults.transformRequest],
    transformResponse: [...axios.defaults.transformResponse, transformResponse],
    withCredentials: true,
  }
  const [, execute] = useAxios(
    {
      ...defaultConfig,
      ...(typeof config === 'string' ? { url: config } : config),
    },
    {
      ...options,
      manual: true,
    }
  )

  const refetch = useCallback(
    async (cfg = {}) => {
      setLoading(true)

      try {
        const token = await getAccessTokenSilently()
        localStorage.setItem('token',token)
        const res = await execute({
          ...cfg,
          headers: { Authorization: `Bearer ${token}` },
        })

        setData(res.data)
        setResponse(res)

        return res
      } catch (err) {
        if (
          err?.response?.status === 403 &&
          window.location.pathname !== '/pricing/mcw' &&
          window.location.pathname !== '/pricing/general' &&
          window.location.pathname !== '/pricing/coach-henry' &&
          window.location.pathname !== '/pricing/coach-g' &&
          window.location.pathname !== '/pricing/coach-stine' &&
          window.location.pathname !== '/pricing/coach-terrance' &&
          window.location.pathname !== '/pricing/coach-dustin' &&
          window.location.pathname !== '/pricing/coach-justin' &&
          window.location.pathname !== '/pricing/coach-greyno' &&
          window.location.pathname !== '/pricing/trainer'
        ) {
          window.location.href = '/pricing/general'
        }
        setError(err)
        return null
      } finally {
        setLoading(false)
      }
    },
    [execute, getAccessTokenSilently]
  )

  useEffect(() => {
    if (!options?.manual) {
      refetch()
    }
  }, [options, refetch])

  return [{ data, error, loading, response }, refetch]
}

export default useApi
