import React, {useContext} from 'react'
import { NavLink } from 'react-router-dom'
import { UserContext } from 'account'

function Drawer({ onAdd = null, showProgram = true, showAthletes = false }) {

  const user = useContext(UserContext)

  return (
    <div className="appBottomMenu">
      {showProgram && user.email && (
        <NavLink exact to="/program/activity" className="item">
          <div className="col">
            <ion-icon
              name="calendar-outline"
              role="img"
              className="md hydrated"
              aria-label="Program"
            />
            <strong>Program</strong>
          </div>
        </NavLink>
      )}

      {onAdd && user.email && (
        <button type="button" className="btn item" onClick={onAdd}>
          <div className="col">
            <div className="action-button large">
              <ion-icon name="add-outline" role="img" className="md hydrated" />
            </div>
          </div>
        </button>
      )}

      {showAthletes && user.email && (
        <NavLink exact to="/trainers" className="item">
          <div className="col">
            <ion-icon
              name="people-outline"
              role="img"
              className="md hydrated"
              aria-label="Athlete"
            />
            <strong>Athletes</strong>
          </div>
        </NavLink>
      )}

      {!onAdd && user.email && (
        <NavLink exact to="/activityLogs" className="item">
          <div className="col">
            <ion-icon
              name="book-outline"
              role="img"
              className="md hydrated"
              aria-label="Report"
            />
            <strong>Logs</strong>
          </div>
        </NavLink>
      )}
      {user.email && (
        <NavLink exact to="/myReport" className="item">
          <div className="col">
            <ion-icon
              name="reader-outline"
              role="img"
              className="md hydrated"
              aria-label="Report"
            />
            <strong>Report</strong>
          </div>
        </NavLink>
      )}
    </div>
  )
}

export default Drawer
