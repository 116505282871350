import { React, useState, useContext, useEffect, useCallback } from 'react'
import useApi from 'common/hooks/useApi'
import { LayoutContext } from 'common/ducks'
import styled from 'styled-components'
import PageTitle from 'common/components/PageTitle'
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { UserContext } from 'account'
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";



function OneRepMax() {
    const user = useContext(UserContext)

    const { setAppLoading } = useContext(LayoutContext)

    const [data, setData] = useState([{name: '', exercise: '', weight_lbs: '',}]);
    const [data2, setData2] = useState([{name: '', exercise: '', weight_lbs: '',}]);
    const [athleteList, setAthleteList] = useState([])
    const [athlete, setAthlete] = useState()


    const AthleteIconDiv = styled.div`
        padding-top: 25px;
        padding-left: 15px;
        padding-bottom: 25px;
        display: flex;
        flex-direction: row;
    `;

    const [{ loading: infoLoading }, info ] = useApi({url: 'builder/onerepmax/'}, {manual: true})
   const [{ loading: listLoading }, list ] = useApi({url: 'builder/onerepmax/athlete_list/'}, {manual: true})

    const [{ loading: sendLoading }, postData] = useApi(
        {
          method: 'POST',
          url: 'builder/onerepmax/',
        },
        { manual: true }
      )

    const [{ loading: putLoading }, putData] = useApi(
        {
          method: 'PUT',
        },
        { manual: true }
      )

   const fetchData = useCallback(
    async () => {
      const response = await info({
        params: {
          user: athlete,
        },
      })
      setData(response?.data.data)
      setData2(response?.data.data2)
    },
    [ info, athlete]
    )

  useEffect(() => {
      fetchData()
    }, [fetchData, info, athlete])

  const fetchList = useCallback(
    async () => {
      const response = await list()
      setAthleteList(response?.data)
    },
    [ list]
  )
  useEffect(() => {
      fetchList()
    }, [fetchList, list])

  useEffect(() => {
    setAppLoading(listLoading || infoLoading)
  }, [listLoading, sendLoading, putLoading, setAppLoading, infoLoading])

  const handleAthleteChange = (event: SelectChangeEvent) => {
        setAthlete(event.target.value);
    };

    const onCellValueChanged = useCallback((event) => {
        const body = {
            user: event.data.user,
            weight_lbs: event.newValue,
            exercise: event.data.id,
        }

        if (event.data.table_id) {
            putData({
                url: `builder/onerepmax/${event.data?.table_id}/`,
                data: body,
            })
        }
        else{
            postData({
                data: body,
            })
        }
  }, []);

    const [columnDefs] = useState([
        { field: "exercise", headerName: 'Exercise', flex: 2, cellStyle: { textAlign: 'center' }},
        { field: "weight_lbs", headerName: 'Weight (lbs)', editable: true, flex: 2, cellStyle: { textAlign: 'center' }}
    ])

    const [columnDefs2] = useState([
        { field: "exercise", headerName: 'Exercise', flex: 2, cellStyle: { textAlign: 'center' }},
        { field: "weight_lbs", headerName: 'Weight (lbs)', flex: 2, cellStyle: { textAlign: 'center' }},
    ])

    return (
        <OneRepMax.Stylesheet>
            <PageTitle title="One Rep Max Table" />
            <AthleteIconDiv>
                <FormControl variant='outlined' style={{paddingRight: '20px', minWidth: '150px', width: '25%'}} >
                <InputLabel >Athlete</InputLabel>
                    <Select
                      value={athlete}
                      onChange={handleAthleteChange}
                    >
                      {athleteList?.map((row) => (
                              <MenuItem value={row?.email}>{row?.name}</MenuItem>
                          ))}
                    </Select>
                </FormControl>
            </AthleteIconDiv>

            <h2>Active</h2>
            <div className="ag-theme-alpine" style={{ height: '40vh', width: '80vw', margin: 'auto' }}>
              <AgGridReact
                rowData={data2}
                columnDefs={columnDefs}
                onCellValueChanged={onCellValueChanged}
                />
            </div>

            <h2>Historic</h2>
            <div className="ag-theme-alpine" style={{ height: '40vh', width: '80vw', margin: 'auto' }}>
                <AgGridReact
                    rowData={data}
                    columnDefs={columnDefs2}
                />
            </div>
        </OneRepMax.Stylesheet>
    )
}

OneRepMax.Stylesheet = styled.div`

    h2 {
        padding: 30px;
    }

    .ag-theme-alpine {
        --ag-header-background-color: teal;
        --ag-header-font-color: white;
    }
    .ag-theme-alpine .ag-header-cell {
        color: white;
    }
    .ag-theme-alpine .ag-header-cell-label {
      justify-content: center;
    }
`

export default OneRepMax
